import React from "react";
import { Layout } from "../layouts";

const slug = (title) => {
  return title
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const ServiceItem = (props) => {
  return (
    <div className="service-item">
      <a id={slug(props.title)}></a>
      <h3 className="service__hd">{props.title}:</h3>
      {props.children}
    </div>
  );
};

const renderServiceLinkColumn = (arr) => {
  return (
    <div className="service-links__column">
      {arr.map((item) => {
        const itemSlug = slug(item.props.title);
        return (
          <a key={itemSlug} href={`#${itemSlug}`} className="service__link">
            - {item.props.title}
          </a>
        );
      })}
    </div>
  );
};

const ServiceItems = (props) => {
  const half = Math.floor(props.children.length / 2);

  return (
    <div>
      <div className="content-wrap">
        <h1 className="content-h2">Leistungen</h1>

        <div className="service-links">
          {renderServiceLinkColumn(props.children.slice(0, half))}
          {renderServiceLinkColumn(props.children.slice(half + 1))}
        </div>
      </div>

      <div className="service-items">{props.children}</div>
    </div>
  );
};

const LeistungenPage = (props) => (
  <Layout location={props.location}>
    <div>
      <ServiceItems>
        <ServiceItem title="Orthopädische Diagnostik und Therapie">
          {" "}
          Untersuchung und Behandlung von Erkrankungen des Bewegungsapparates
          wie Wirbelsäulen-, Muskel-, Sehnen- und Gelenkbeschwerden.
        </ServiceItem>
        <ServiceItem title="Manuelle Medizin">
          (Zusatzbezeichnung Chirotherapie)
          <br />
          Manuelle Untersuchung und Behandlung von Funktionsstörungen wie
          Blockierungen an Wirbelsäule, Gelenken, Faszien und Muskeln.
        </ServiceItem>
        <ServiceItem title="Diagnose und Behandlung von Knochenerkrankungen">
          Die häufigste Knochenerkrankung ist die Osteoporose. Durch
          Früherkennung, v.a. mit der Knochendichtemessung und rechtzeitige
          Behandlung sollen Komplikationen wie Wirbelkörperbruch und
          Schenkelhalsbruch möglichst verhindert werden.
        </ServiceItem>
        <ServiceItem title="Unfallbehandlung">
          Behandlung von Freizeit- und Sportunfällen wie Verstauchungen,
          Bandrisse und Knochenbrüche.
        </ServiceItem>
        <ServiceItem title="Sportmedizin">
          (Zusatzbezeichnung Sportmedizin)
          <br />
          Behandlung von Sportverletzungen und Überlastungsbeschwerden im Sport
          wie Tennisarm, Läuferknie, Achillessehnenbeschwerden. Sportberatung.
        </ServiceItem>

        <ServiceItem title="Röntgen">
          Digitale Direktradiographie mit verminderter Strahlenbelastung zum
          Nachweis oder Ausschluss von knöchernen Verletzungen, degenerativen
          Veränderungen, Fehlstellungen, anlagebedingten Fehlbildungen oder
          Tumoren.
        </ServiceItem>
        <ServiceItem title="Ultraschall">
          Mit Ultraschall können am Bewegungsapparat Weichteilgewebe wie Sehnen
          oder Muskeln gut untersucht werden. Die Methode kommt zum Beispiel bei
          der Abklärung von Schulter- oder Achillessehnenbeschwerden und beim
          Verdacht auf Muskelverletzungen zum Einsatz.
        </ServiceItem>

        <ServiceItem title="Labordiagnostik">
          Blutuntersuchungen werden bei Verdacht auf entzündlich rheumatische
          Erkrankungen und zur Osteoporoseabklärung durchgeführt.
        </ServiceItem>

        <ServiceItem title="Injektionsbehandlung">
          Injektionsbehandlungen an Wirbelsäule und Gelenken, Neuraltherapie.
        </ServiceItem>

        <ServiceItem title="Orthopädische Schmerztherapie">
          Individuell abgestimmte Schmerztherapie bei der einzelne oder mehrere
          Verfahren zum Einsatz kommen können, wie beispielsweise manuelle
          Therapie, Injektionen, medikamentöse Schmerztherapie, Verbände,
          Bandagen, physikalische Therapiemaßnahmen, TENS, Krankengymnastik,
          Entspannungsverfahren, Einleitung einer psychologischen Mitbetreuung.
        </ServiceItem>

        <ServiceItem title="Akupunktur">
          (Akupunkturvollausbildung mit B-Diplom, Ausbildung bei der
          Forschungsgruppe Akupunktur).
          <br />
          Zur Behandlung orthopädischer Erkrankungen wie Nacken- und
          Kopfschmerzen, Schultererkrankungen, Tennisarm, Hüftbeschwerden und
          Ischias. Bei den Diagnosen LWS-Syndrom oder Kniearthrose werden von
          den gesetzlichen Krankenkassen die Kosten für in der Regel 10
          Akupunktursitzungen übernommen.
        </ServiceItem>

        <ServiceItem title="Physikalische Therapie">
          Reizstrom, Ultraschallbehandlung, Wärmetherapie.
        </ServiceItem>

        <ServiceItem title="Stoßwellentherapie">
          bei Kalkschulter, Tennisarm, Fersensporn, Achillessehnenbeschwerden
          und Sehnenansatzerkrankungen.
        </ServiceItem>

        <ServiceItem title="Arthrosetherapie">
          Hyaluronsäureinjektionen zur Stabilisierung des Gelenkknorpels,
          Schmerzlinderung und Funktionsverbesserung (z.B. bei Kniearthrose).
        </ServiceItem>

        <ServiceItem title="Pulsierende Magnetfeldtherapie">
          bei Schmerzen durch Knie-, Hüft- oder Schulterarthrose und bei
          degenerativen Wirbelsäulenerkrankungen.
        </ServiceItem>
      </ServiceItems>
    </div>
  </Layout>
);

export default LeistungenPage;
